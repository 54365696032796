import { Container, Table, Text } from "@mantine/core";
import { storage } from "../../util/storage";
import { secondsToInputValue, formatDate } from "../../util/time";

const Reports = () => {
  const reports = storage.get("timer.reports", []);

  return (
    <Container p="lg">
      <div className="reports-body">
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Duration</th>
              <th>Speaker Count</th>
              <th>Skipped</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report, index) => (
              <tr key={index}>
                <td>{formatDate(new Date(report.date))}</td>
                <td>
                  <Text
                    component="span"
                    sx={(theme) => ({
                      color:
                        report.completedIn > report.duration
                          ? theme.colors.red[6]
                          : theme.colors.green[6],
                    })}
                  >
                    {secondsToInputValue(report.completedIn)}
                  </Text>{" "}
                  / {secondsToInputValue(report.duration)}
                </td>
                <td>
                  {report.speakerCount} / {report.userCount}
                </td>

                <td className="not-speaking">{report.skipped}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Container>
  );
};

export default Reports;
