import localforage from "localforage";

export class Storage {
  static Local = "localStorage";
  static Session = "sessionStorage";
  static IndexedDB = "indexedDB";

  static Noop = () => {
    return "no-op";
  };

  static OverrideUnparsableValue = ({
    instance,
    storageMethod,
    key,
    value,
  }) => {
    const newValue = JSON.stringify(value);
    instance.method(storageMethod).set(key, newValue);
  };

  static ThrowError = ({ message, error }) => {
    console.error(message, error);
  };

  static HandleError = {
    IncorrectParameter: Storage.ThrowError,
    EmptyValue: Storage.Noop,
    // UnparsableValue: Storage.OverrideUnparsableValue,
    // UnparsableValue: Storage.ThrowError,
    UnparsableValue: Storage.Noop,
    UnserializableValue: Storage.Noop,
    UnknownStorageMethod: Storage.Noop,
  };

  constructor(defaultMethod = Storage.Local) {
    this.defaultMethod = defaultMethod;
    this.defaultMethod = defaultMethod;
  }

  get(key, defaultValue) {
    return this.methods.get(this.defaultMethod, key, defaultValue);
  }

  set(key, value) {
    return this.methods.set(this.defaultMethod, key, value);
  }

  remove(key) {
    return this.methods.remove(this.defaultMethod, key);
  }

  clear() {
    return this.methods.clear(this.defaultMethod);
  }

  subscribe = (key, callback) => {
    const handler = (event) => {
      if (event.key === key) {
        callback(event.newValue ? JSON.parse(event.newValue) : null);
      }
    };

    window.addEventListener("storage", handler);

    return () => window.removeEventListener("storage", handler);
  };

  methods = {
    get: (storageMethod, key, defaultValue) => {
      const syncMethod = () => {
        if (typeof key !== "string") {
          Storage.HandleError.IncorrectParameter({
            message: "Storage.get() expects a string as first argument",
          });
          return defaultValue;
        }

        const value = window[storageMethod].getItem(key);

        if (value) {
          if (this.checkEmptyValue(value)) {
            Storage.HandleError.EmptyValue();
            return defaultValue;
          }
          try {
            return JSON.parse(value);
          } catch (e) {
            Storage.HandleError.UnparsableValue();
          }
        }

        return defaultValue;
      };

      const indexedDBMethod = async () => {
        const value = await localforage.getItem(key);

        if (this.checkEmptyValue(value)) {
          Storage.HandleError.EmptyValue();
          return defaultValue;
        }

        return value;
      };

      switch (storageMethod) {
        case Storage.Local:
        case Storage.Session:
          return syncMethod();

        case Storage.IndexedDB:
          return indexedDBMethod();
        default:
          return defaultValue;
      }
    },
    set: (storageMethod, key, value) => {
      const syncMethod = () => {
        if (typeof key !== "string") {
          Storage.HandleError.IncorrectParameter({
            message: "Storage.set() expects a string as first argument",
          });
          return false;
        }

        try {
          window[storageMethod].setItem(key, JSON.stringify(value));
          return true;
        } catch (e) {
          Storage.HandleError.UnserializableValue();
        }

        return false;
      };

      const indexedDBMethod = async () => {
        const result = await localforage.setItem(key, value);
        return result;
      };

      switch (storageMethod) {
        case Storage.Local:
        case Storage.Session:
          return syncMethod();

        case Storage.IndexedDB:
          return indexedDBMethod();
        default:
          return syncMethod();
      }
    },
    remove: (storageMethod, key) => {
      const syncMethod = () => {
        if (typeof key !== "string") {
          Storage.HandleError.IncorrectParameter({
            message: "Storage.remove() expects a string as first argument",
          });
          return false;
        }

        window[storageMethod].removeItem(key);
        return true;
      };

      const indexedDBMethod = async () => {
        return await localforage.removeItem(key);
      };

      switch (storageMethod) {
        case Storage.Local:
        case Storage.Session:
          return syncMethod();

        case Storage.IndexedDB:
          return indexedDBMethod();
        default:
          return syncMethod();
      }
    },
    clear: (storageMethod) => {
      const syncMethod = () => {
        window[storageMethod].clear();
      };

      const indexedDBMethod = async () => {
        return await localforage.clear();
      };

      switch (storageMethod) {
        case Storage.Local:
        case Storage.Session:
          return syncMethod();

        case Storage.IndexedDB:
          return indexedDBMethod();
        default:
          return syncMethod();
      }
    },
  };

  checkEmptyValue(value) {
    return (
      value === null ||
      value === undefined ||
      value === "undfined" ||
      value === ""
    );
  }

  // Chainable method function
  // storage.method(Storage.IndexedDB).get('key', 'default value')
  method(storageMethod) {
    return new Proxy(this, {
      get: (target, property) => {
        return (...args) =>
          // @ts-ignore - we know that property is a key of target.methods
          target.methods[property].bind(target)(storageMethod, ...args);
      },
    });
  }
}

export const storage = new Storage();
