import { createSlice } from "@reduxjs/toolkit";
import { storage } from "../../util/storage";

const initialState = {
  totalTime: 60 * 15,
  users: [],
  speakers: [],
};

const timeSlice = createSlice({
  name: "time",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
      storage.set("timer.users", state.users);
    },
    deleteUser: (state, action) => {
      state.users = state.users.filter((item) => item.value !== action.payload);
      storage.set("timer.users", state.users);
    },
    setSpeakers: (state, action) => {
      state.speakers = action.payload;
      storage.set("timer.lastSpeakers", state.speakers);
    },
    setTotalTime: (state, action) => {
      state.totalTime = action.payload;
    },
  },
});

export const {
  setUsers,
  deleteUser,
  setSpeakers,
  setTotalTime,
} = timeSlice.actions;

export default timeSlice.reducer;
