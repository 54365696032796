import React from "react";

import "./TimerPage.css";

import { useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

import {
  useMantineTheme,
  Title,
  Stack,
  Text,
  List,
  Box,
  Paper,
  Button,
  Group,
} from "@mantine/core";

import {
  IconX,
  IconPlayerPlay,
  IconPlayerPause,
  IconArrowRight,
  IconArrowLeft,
  IconRefresh,
} from "@tabler/icons-react";

import { storage } from "../../util/storage";
import { useTimer, secondsToInputValue } from "../../util/time";

function shuffleArray(array) {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}

export default function TimerPage() {
  const [ready, setReady] = React.useState(false);
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const users = useSelector((state) => state.time.users);
  const totalTime = useSelector((state) => state.time.totalTime);
  const speakers = useSelector((state) => state.time.speakers);
  const [shuffled, setShuffled] = React.useState(shuffleArray(speakers));
  const [currentSpeakerIndex, setCurrentSpeakerIndex] = React.useState(0);

  const perSpeaker = speakers.length > 0 ? totalTime / speakers.length : 0;

  const {
    value: countDownValue,
    label: countDown,
    pause: pauseCountDown,
    resume: resumeCountDown,
    exceeded: countDownExceeded,
    paused,
  } = useTimer("down", totalTime, 0);
  const {
    label: countUp,
    reset: resetCoutUp,
    pause: pauseCountUp,
    resume: resumeCountUp,
    exceeded: countUpExceeded,
  } = useTimer("up", 0, perSpeaker);

  const exceeded = countUpExceeded || countDownExceeded;

  const skippedCount = React.useRef(0);

  React.useEffect(() => {
    if (speakers.length === 0) {
      navigate("/");
    } else {
      setReady(true);
    }
  }, [setReady, speakers, navigate]);

  const nextSpeaker = () => {
    setCurrentSpeakerIndex(currentSpeakerIndex + 1);
    resetCoutUp();
  };

  const skipSpeaker = () => {
    const currentSpeaker = shuffled[currentSpeakerIndex];
    setShuffled([
      ...shuffled.slice(0, currentSpeakerIndex),
      ...shuffled.slice(currentSpeakerIndex + 1),
      currentSpeaker,
    ]);
    resetCoutUp();
    skippedCount.current++;
  };

  const togglePlaying = () => {
    if (paused) {
      resumeCountDown();
      resumeCountUp();
    } else {
      pauseCountDown();
      pauseCountUp();
    }
  };

  const endSession = () => {
    const report = {
      date: new Date(),
      speakerCount: shuffled.length,
      completedIn: totalTime - countDownValue,
      userCount: users.length,
      duration: totalTime,
      skipped: skippedCount.current,
    };

    storage.set("timer.reports", [...storage.get("timer.reports", []), report]);

    navigate("/finish");
  };

  if (!ready) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: exceeded ? theme.colors.red[8] : theme.colors.brand[5],
        display: "flex",
        flexDirection: "row",
      }}
      className="main-container"
    >
      <Box mx="lg" top={100} pos="fixed">
        <Paper p="lg">
          <List>
            <List.Item>What did you do yesterday?</List.Item>
            <List.Item>What will you do today?</List.Item>
            <List.Item>Are there any impediments in your way?</List.Item>
          </List>
        </Paper>
      </Box>

      <Group p="lg" w="100vw" position="apart" pos="fixed">
        <Button h={60} variant="white" component={Link} to="/">
          <IconArrowLeft />
        </Button>
        <Button h={60} variant="white" component={Link} to="/finish">
          <IconX />
        </Button>
      </Group>

      <Stack
        mw={600}
        m="auto"
        h="100vh"
        py={60}
        sx={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <Stack spacing={0} sx={{ alignItems: "center" }}>
          <Title fw={500} size={18} color="white">
            Speaker
          </Title>
          <Text fw={700} color="white" size={36}>
            {shuffled[currentSpeakerIndex]}
          </Text>
        </Stack>

        <Stack spacing={0} align="center">
          <div>
            <Title size={160} color="white">
              {countDown}
            </Title>
          </div>
          <div>
            <Title size={40} color="white">
              {countUpExceeded && "+"} {countUp} of{" "}
              {secondsToInputValue(perSpeaker)}
            </Title>
          </div>
        </Stack>
        <div>
          <Title size={18} color="white" order={2}>
            {currentSpeakerIndex + 1 + "/" + shuffled.length}
          </Title>
        </div>
        <Group>
          <Button
            w={260}
            rightIcon={<IconRefresh size={22} />}
            variant="white"
            size="xl"
            disabled={currentSpeakerIndex === shuffled.length - 1}
            onClick={skipSpeaker}
          >
            Skip
          </Button>
          <Button onClick={togglePlaying} h={60} variant="white">
            {!paused ? <IconPlayerPause /> : <IconPlayerPlay />}
          </Button>
          {currentSpeakerIndex !== shuffled.length - 1 ? (
            <Button
              w={260}
              rightIcon={<IconArrowRight size={22} />}
              variant="white"
              size="xl"
              onClick={nextSpeaker}
            >
              <Stack spacing={0} mr="sm">
                <span>Next</span>
                <Text size="sm">{shuffled[currentSpeakerIndex + 1]}</Text>
              </Stack>
            </Button>
          ) : (
            <Button
              w={260}
              rightIcon="🎉"
              variant="white"
              size="xl"
              onClick={endSession}
            >
              Finish
            </Button>
          )}
        </Group>
      </Stack>
    </div>
  );
}
