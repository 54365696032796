import React from "react";

export function secondsToInputValue(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = parseInt(seconds % 60);

  if (minutes < 0 || remainingSeconds < 0) return "00:00";

  return `${minutes < 10 ? `0${minutes}` : minutes}:${
    remainingSeconds < 10 ? "0" : ""
  }${remainingSeconds}`;
}

export function useTimer(type, initialValue = 0, limit = Infinity) {
  const [paused, setPaused] = React.useState(false);
  const [timer, setTimer] = React.useState(initialValue);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (paused) return;
      if (type === "up") {
        setTimer((current) => current + 1);
      } else if (type === "down") {
        setTimer((current) => current - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [type, paused]);

  const reset = () => setTimer(initialValue);

  const pause = () => setPaused(true);

  const resume = () => setPaused(false);

  const exceeded = type === "up" ? timer > limit : timer < limit;

  return {
    value: timer,
    label: secondsToInputValue(timer),
    reset,
    pause,
    resume,
    paused,
    exceeded,
  };
}

export function formatDate(date) {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}
