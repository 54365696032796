import React from "react";

import "./HomePage.css";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Title,
  Text,
  Container,
  Paper,
  MultiSelect,
  Header,
  ActionIcon,
  Group,
  createStyles,
} from "@mantine/core";

import { TimeInput } from "@mantine/dates";

import {
  setUsers,
  setSpeakers,
  deleteUser,
  setTotalTime,
} from "../../redux/reducers/timeSlice";
import logo from "../../data/images/logo.png";
import { secondsToInputValue } from "../../util/time";
import { Link } from "react-router-dom";
import {
  IconClipboardData,
  IconArrowRight,
  IconTrash,
  IconClock,
} from "@tabler/icons-react";

const useStyles = createStyles(() => ({
  wrapper: {
    minHeight: 50,
  },
  input: {
    minHeight: 50,
  },
  values: {
    minHeight: 50,

    "& input": {
      fontSize: 16,
    },
  },
}));

const SelectItem = React.forwardRef(({ image, label, ...others }, ref) => {
  const dispatch = useDispatch();

  const handleDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(deleteUser(label));
  };

  return (
    <div ref={ref} {...others}>
      <Group noWrap position="apart">
        <Text>{label}</Text>
        <ActionIcon
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onClick={handleDelete}
          color="red"
        >
          <IconTrash size={14} />
        </ActionIcon>
      </Group>
    </div>
  );
});

export default function HomePage() {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const totalTimeRef = React.useRef();
  const perSperkerRef = React.useRef();

  const users = useSelector((state) => state.time.users);
  const speakers = useSelector((state) => state.time.speakers);
  const totalTime = useSelector((state) => state.time.totalTime);
  const perSpeaker = speakers.length > 0 ? totalTime / speakers.length : 0;

  return (
    <>
      <div className="body">
        <Header
          className="header__toolbar"
          elevation={0}
          color="transparent"
          position="static"
          height={64}
          px="md"
        >
          <img className="logo" src={logo} alt="macellan"></img>
          <Group spacing="xs" sx={{ flex: 1, alignItems: "baseline" }}>
            <Title size={24} color="brand" order={6}>
              Meeting Timer
            </Title>
            <Text size={14}>from Macellan</Text>
          </Group>
          <Button
            component={Link}
            to="/reports"
            leftIcon={<IconClipboardData size={18} />}
          >
            Reports
          </Button>
        </Header>

        <div className="content">
          <div className="overlay" />
          <Container h="100%" size="xs">
            <Paper className="card">
              <div className="card__content">
                <Title my="xs" order={1} sx={{ fontSize: 28 }} color="brand">
                  Create A Timer
                </Title>
                <form className="form">
                  <div className="label">
                    <label>
                      <Text color="dimmed">Total time</Text>
                    </label>
                    <TimeInput
                      size="lg"
                      ref={totalTimeRef}
                      value={secondsToInputValue(totalTime)}
                      onChange={(e) => {
                        const value = e.target.valueAsNumber / 60 / 1000;

                        if (!Number.isNaN(value)) {
                          dispatch(setTotalTime(value));
                        }
                      }}
                      rightSection={
                        <ActionIcon
                          onClick={() => totalTimeRef.current.showPicker()}
                        >
                          <IconClock size="1rem" stroke={1.5} />
                        </ActionIcon>
                      }
                      w={260}
                    />
                  </div>

                  <div className="label">
                    <label>
                      <Text color="dimmed">Speakers</Text>
                    </label>
                    <MultiSelect
                      searchable
                      creatable
                      open
                      classNames={classes}
                      getCreateLabel={(query) => `+ Create ${query}`}
                      itemComponent={SelectItem}
                      onCreate={(query) => {
                        const item = { value: query, label: query };
                        dispatch(setUsers([...users, item]));
                        return item;
                      }}
                      w={260}
                      value={speakers}
                      onChange={(value) => {
                        dispatch(setSpeakers(value));
                      }}
                      data={users}
                      placeholder="Select speakers"
                    />
                  </div>

                  <div className="label">
                    <label>
                      <Text color="dimmed">Time per speaker</Text>
                    </label>

                    <TimeInput
                      size="lg"
                      ref={perSperkerRef}
                      value={secondsToInputValue(perSpeaker)}
                      readOnly
                      rightSection={
                        <ActionIcon
                          onClick={() => perSperkerRef.current.showPicker()}
                        >
                          <IconClock size="1rem" stroke={1.5} />
                        </ActionIcon>
                      }
                      w={260}
                    />
                  </div>
                </form>
              </div>
              <div className="card__actions">
                <Button
                  disabled={speakers.length === 0}
                  size="lg"
                  fullWidth
                  rightIcon={<IconArrowRight />}
                  to={"/timer"}
                  component={Link}
                >
                  Start meeting{" "}
                  {speakers.length > 0 &&
                    `with ${speakers.length} ${
                      speakers.length === 1 ? "person" : "people"
                    }`}
                </Button>
              </div>
            </Paper>
          </Container>
        </div>
      </div>
    </>
  );
}
