import "./Finish.css";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Box, Button, Stack, Text } from "@mantine/core";

const Finish = () => {
  const passingMinute = useSelector((state) => state.time.passingMinute);
  const passingSecond = useSelector((state) => state.time.passingSecond);
  const countSpeaker = useSelector((state) => state.time.countSpeaker);
  const totalNumber = useSelector((state) => state.time.totalNumber);
  const notSpeaking = useSelector((state) => state.time.notSpeaking);

  const [, setData] = React.useState(() => {
    const localData = localStorage.getItem("meetingData");
    return localData ? JSON.parse(localData) : [];
  });
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = today.getFullYear();
  const formattedDate = `${day}.${month}.${year}`;

  const navigate = useNavigate();

  React.useEffect(() => {
    const handleBackButton = () => navigate("/");
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handleBackButton);
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);

  React.useEffect(() => {
    const newData = {
      tarih: formattedDate,
      sure: { passingMinute, passingSecond },
      kisiSayisi: { countSpeaker, totalNumber },
      katilmayanKisiler: notSpeaking,
    };
    setData((prevState) => [...prevState, newData]);
  }, [
    countSpeaker,
    notSpeaking,
    formattedDate,
    passingMinute,
    passingSecond,
    totalNumber,
  ]);

  return (
    <Box sx={(theme) => ({ backgroundColor: theme.colors.brand[5] })} h="100vh">
      <div className="meet-complete">
        <Stack align="center" spacing="xs">
          <Text> Meeting Complete 🎉</Text>
          <div>
            <Button component={Link} to="/" size="lg" variant="white">Go to Homepage</Button>
          </div>
        </Stack>
      </div>
    </Box>
  );
};

export default Finish;
