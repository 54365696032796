import React from "react";
import HomePage from "./pages/HomePage/HomePage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import TimerPage from "./pages/TimerPage/TimerPage";
import Finish from "./pages/Finish/Finish";
import Reports from "./pages/Reports/Reports";
import { Provider, useDispatch } from "react-redux";
import store from "./redux/store";
import { MantineProvider } from "@mantine/core";
import { storage } from "./util/storage";
import { setUsers, setSpeakers } from "./redux/reducers/timeSlice";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <div>Oops! There was an error.</div>,
  },
  {
    path: "/timer",
    element: <TimerPage />,
    errorElement: <div>Oops! There was an error.</div>,
  },
  {
    path: "/reports",
    element: <Reports />,
    errorElement: <div>Oops! There was an error.</div>,
  },
  {
    path: "/finish",
    element: <Finish />,
    errorElement: <div>Oops!</div>,
  },
]);

function Wrapper() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    const savedUsers = storage.get("timer.users", []);
    const lastSpeakers = storage.get("timer.lastSpeakers", []);
    dispatch(setUsers(savedUsers));
    dispatch(setSpeakers(lastSpeakers));
  }, [dispatch]);

  return <RouterProvider router={router} />;
}

function App() {
  return (
    <Provider store={store}>
      <MantineProvider
        theme={{
          fontFamily: "Urbanist",
          colors: {
            brand: [
              "#8696ae",
              "#8696ae",
              "#8696ae",
              "#1b55af",
              "#1b55af",
              "#1b55af",
              "#1b55af",
              "#0e2d5d",
              "#0e2d5d",
              "#0e2d5d",
            ],
          },
          primaryColor: "brand",
        }}
        withGlobalStyles
        withNormalizeCSS
      >
        <Wrapper />
      </MantineProvider>
    </Provider>
  );
}

export default App;
